<template>
	<div class="news">
		<div class="banner">
			<!-- <img class="font" src="../assets/news/font.png" alt="" /> -->
			<div class="font">{{ $t('news.title') }}</div>
		</div>
		<div class="body">
			<div @click="go(item)" class="item" v-for="(item, i) in blogList" :key="i">
				<div class="tag">NEW</div>
				<div class="title">
					{{ item.title }}
				</div>
				<div class="font">
					{{ item.topic }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fetchData } from '@/api/index';

const { t, locale } = useI18n();
const blogList = ref([
	// {
	// 	linkUrl:
	// 		'https://www.timesnewswire.com/pressrelease/explore-the-future-and-move-towards-the-interstellar-m3-dao-leads-the-new-era-of-web3-0/',
	// 	topic:
	// 		'M3 DAO is a project that promotes mankind towards a new era of cosmic civilization through technological innovation and community cooperation.',
	// 	title:
	// 		'《Explore the Future and Move Towards the Interstellar: M3 DAO Leads the New Era of Web3.0》',
	// },
]);
const go = (item) => {
	window.open(item.linkUrl, '_blank');
};
console.log(locale.value)
let lang = 0
if(locale.value == "zh"){
	lang = 1
}else {
	lang = 0
}
fetchData(lang) // axios请求在这里
	.then((res) => {
		console.log(res);
		blogList.value = res.data;
	})
	.catch((err) => {
		console.log(err);
	});
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.news {
		width: 100%;
		background: #000000;
		color: #ffffff;

		.banner {
			width: 100%;
			height: 260px;
			background: url('../assets/h5news/news.png');
			background-size: 100% 260px;
			.font {
				// width: 538px;
				color: #f5f5f5;
				font-size: 36px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-align: center;
				padding: 112px 32px 0;
			}
		}
		.body {
			width: 100%;
			// margin-top: -8px;
			padding: 21px;
			.item {
				width: 100%;
				height: 110px;
				font-size: 12px;
				font-weight: 400;
				color: rgba(101, 106, 111, 1);
				position: relative;
				cursor: pointer;
				margin-bottom: 28px;
				.title {
					width: 86%;
					font-size: 16px;
					font-weight: 700;
					color: rgba(255, 255, 255, 1);
					margin-bottom: 14px;
					overflow: hidden; //超出的文本隐藏
					text-overflow: ellipsis; //溢出用省略号显示
					white-space: nowrap; //溢出不换行
				}
				.tag {
					width: 33px;
					height: 18px;
					background: rgba(31, 120, 211, 1);
					color: rgba(255, 255, 255, 1);
					position: absolute;
					right: 0;
					top: 0px;
					text-align: center;
					font-size: 8.27px;
					line-height: 18px;
				}
				.font {
					width: 96%;
					padding-left: 1%;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box; //作为弹性伸缩盒子模型显示。
					-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
					-webkit-line-clamp: 2; //显示的行
				}
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.news {
		width: 100%;
		overflow: auto;
		height: calc(100vh - 180px);
		background: #000000;
		color: #ffffff;
		.banner {
			width: 100%;
			height: 400px;
			background: url('../assets/news/news.png');
			background-size: 1920px 400px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			.font {
				width: 538px;
				color: #f5f5f5;
				font-size: 64px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-align: center;
			}
		}
		.body {
			width: 100%;
			padding: 92px 500px 222px;
			.item {
				width: 100%;
				height: 161px;
				border-bottom: 1px solid rgba(101, 106, 111, 1);
				font-size: 14px;
				font-weight: 400;
				color: rgba(101, 106, 111, 1);
				padding: 36px 0;
				position: relative;
				cursor: pointer;
				.title {
					width: 86%;
					font-size: 24px;
					font-weight: 700;
					color: rgba(255, 255, 255, 1);
					margin-bottom: 19px;
					overflow: hidden; //超出的文本隐藏
					text-overflow: ellipsis; //溢出用省略号显示
					white-space: nowrap; //溢出不换行
				}
				.tag {
					width: 48px;
					height: 28px;
					background: rgba(31, 120, 211, 1);
					color: rgba(255, 255, 255, 1);
					position: absolute;
					right: 0;
					top: 36px;
					text-align: center;
					line-height: 28px;
				}
				.font {
					width: 96%;
					padding-left: 1%;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box; //作为弹性伸缩盒子模型显示。
					-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
					-webkit-line-clamp: 2; //显示的行
				}
			}
		}
	}
}
</style>
